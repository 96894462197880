.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  width: 500px;
  height: 500px;
  overflow: auto;
  position: absolute;
}
.ConstantModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  z-index: 999;
  width: 1200px;
}
