
/* Title styling */
.page-title {
    font-size: 1.17rem;
    color: #333;
    margin-bottom: 1.3rem;
}
/* Container styling */
#base {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

/* Solution item styling */
.solution-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-left: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    width: 80%;
    max-width: 600px;
    background-color: #fff;
    transition: background-color 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    align-items: flex-start;
}

/* Hover effect for solution item */
.solution-item:hover {
    background-color: #f9f9f9;
    box-shadow: 0 4px .4vw rgba(0, 0, 0, 0.1);
}

/* Solution card layout */
.solution-card {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Icon styling (optional) */
.solution-icon {
    flex-shrink: 0;
}

.icon-image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
}

/* Content styling */
.solution-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.solution-title {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: .3vw;
    padding-left: 5px;
}

.solution-description {
    font-size: 0.85rem;
    color: #666;
    min-height: 13px;
    padding-left: 5px;
}
.biggerLink {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-left: 5px;
    border: 1px solid #ddd;
    border-radius: 12px;
    width: 80%;
    max-width: 600px;
    background-color: #fff;
    transition: background-color 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    align-items: flex-start;
}

/* Hover effect for solution item */
.biggerLink:hover {
    background-color: #f9f9f9;
    box-shadow: 0 4px .4vw rgba(0, 0, 0, 0.1);
}
/* External link icon styling */
.external-link-icon {
    flex-shrink: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.content-header {
    display: flex;
    width: 80%;
    max-width: 600px;
    align-content: flex-start;
    font-size: 1.17rem;
}