.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  width: 500px;
  height: 500px;
  overflow: auto;
  position: absolute;
}
.UserModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  z-index: 999;
}
.search {
  position: absolute;
  left: 10px;
  top: 105px;
  border-color: #808080FF;
  padding: .5vw;
}
.search-label {
  color: rgba(0, 0, 0, 0.87);
  fontWeight: 500;
  lineHeight: 1.5rem;
  fontSize: 0.875rem;
}
div.user-modal-text-field {
  margin: .6rem 0 .3rem 0;
}
@media (max-width: 768px) {
  .search {
    top: 90px;
  }
}

@media (max-width: 480px) {
  .search {
    top: 85px;
  }
}