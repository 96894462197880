html {
  font-size: 100%;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: hidden;*/
}
div#root {
  height: 100%;
}
html, body {
  height: 100%;
}
/* Page container styling */
.page-container {
  font-family: 'Arial', sans-serif;
  text-align: left;
  padding-top: 100px;
  background-color: #f4f4f9;
  min-height: 90vh;
}
body #mainMount #domain-dash .dashboard-container-list .actions-right, html #mainMount #domain-dash .dashboard-container-list .actions-right {
  border-right: 1px solid #c2c6c9;
  padding: 10px 0;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
div.root, div.App, div.content, div#mfi, div#row>table {
  width: 100%;
  height: 100%;
}
div.content {
  background-color: white;
  width: 90%;
  height: calc(100% - 57px);
  min-width: 1480px;
  margin: 0 auto;
  padding-top: .6vw;
  overflow: auto;
}


div#router-div {
  width: 100%;
  height: 100%;
}
nav#router-nav {
  display: flex;
  flex-direction: column;
  height: 4rem;
  min-height: fit-content;
  max-height: 75px;
  color: #333; /* Dark text */
  justify-content: space-between;
  padding: .3rem 0 0 1rem;
  font-family: 'Helvetica', 'Arial', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #e8eff6;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
nav#router-nav ul {
  list-style: none;
  margin: 0;
  align-content: center;
}
nav#router-nav ul li a {
  color: #333; /* Dark text for links */
  text-decoration: none;
  font-size: 1.08rem;
  transition: color 0.3s;
}
nav#router-nav ul li a:hover {
  color: #007acc; /* Highlight color for hover */
}
div#header {
  display: flex;
  justify-content: space-between;
}
div#header ul, div#header div {
  flex: 1;
}
nav#router-nav ul.crumbar-ul {
  padding-left: 0px;
  align-content: flex-start;
}
nav#router-nav ul.crumbar-ul li {
  padding-left: 0;
}
nav#router-nav ul.crumbar-ul li span {
   padding-right: 0.2rem;
   padding-left: 0.1rem;
   font-size: 0.8rem;
   font-weight: 100;
 }
nav#router-nav ul.crumbar-ul a {
  font-size: 0.9rem;
  font-weight: 400;
}
.navbar-ul, .crumbar-ul {
  padding: 0px;
  color: black;
}
.crumbar-ul.row2 {
  padding-bottom: 0.6rem;
}
.navbar-ul {
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px;
}
#router-nav .crumbar-ul a.onPage, #router-nav .crumbar-ul a:visited.onPage {
  color: #125399;
  font-weight: 550;
}
div#logo {
  display: flex;
  padding-left:0px;
  padding-top: .2rem;
  padding-bottom: .2rem;
  text-align: center;
}


* {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-red {
  color: red;
}

.font-bold {
  font-weight: bold;
}

div.full-width {
  width: 100%;
}

.full-height, div.full-height {
  height: 100%;
}

.left-text {
  text-align: left;
}

li {
  font-size: 1.08rem;
  display: inline;
}

.d-none {
  display: none!important;
}

div#root {
  /*padding-top: 20px;*/
  /*padding-left: 50px;*/
  /*padding-right: 50px;*/
  height: 100%;
  background-color: #f8f8f8;
  /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0 0 0 / 20%)), to(rgba(0, 0, 0, .25)));*/
  /*background-image: url("../images/sky-535026_1920.jpg");*/
  /*background-color: #fffff8;*/
}
.hidden {
  display: none;
}

.flex {
  display: flex;
}

/*.App-header {*/
/*  !*background-color: #282c34;*!*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td.changed, tr.changed {
  border-color: #FFFF00;
  box-shadow: 0 0 .6vw #FFFF00;
}

a.underline-link {
  text-decoration: underline;
}
@media only screen and (min-width: 601px)
.row .col.m6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}
.row .col {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}
.valign-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
li.username-container {
  height: 64px;
  margin: 0 auto 0 20px;
}
.dropdown-button {
  align-items: center;
  background-color: #e7ecee;
  border-radius: 12px;
  color: #3e4244;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  padding: 0 20px;
}
li a.active {
  background-color: transparent;
  box-shadow: inset 0 -4px 0 #0e79d0;
  color: #000;
}
.username-maxwidth {
  max-width: 300px;
  min-width: 100px;
  overflow: hidden!important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-capitalize {
  text-transform: capitalize;
}

@media print {
  div.content {
    height: 100%;
    overflow: visible;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  body {
    overflow: hidden;
  }

  div.root, div.App, div.content, div#mfi, div#row > table {
    width: 100%;
    height: 100%;
  }

  div.content {
    background-color: white;
    width: 90%;
    height: calc(100% - 57px);
    min-width: 1480px;
    margin: 0 auto;
    padding-top: .6vw;
    overflow: auto;
  }

  div#router-div {
    width: 100%;
    height: 100%;
  }

  nav#router-nav {
    height: fit-content;
  }

  div#header {
    box-shadow: 0 .2vw .6vw 0 rgba(194, 229, 250, 0.82);
    display: flex;
    justify-content: space-between;
  }

  #header.navbar-ul, #header.crumbar-ul {
    display: flex;
    align-items: center;
    padding: 0px;
    color: white;
  }

  .navbar-ul a:hover {
    text-decoration: underline;
  }

  .navbar-ul a:visited, .navbar-ul a, .crumbar-ul a:visited, .crumbar-ul a {
    color: #007acc;
    font-family: 'Helvetica', 'Arial', sans-serif;
  }

  li {
    padding-left: 2vw;
    padding-right: 2vw;
    list-style: none;
    font-weight: 550;
    font-size: 1.08rem;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .font-red {
    color: red;
  }

  .font-bold {
    font-weight: bold;
  }

  div.full-width {
    width: 100%;
  }

  .full-height, div.full-height {
    height: 100%;
  }

  .left-text {
    text-align: left;
  }

  /*div#logo-img {*/
    /*color: white;*/
    /*background-image: url("../images/logo-dark.png");*/
    /*background-size: contain;*/
    /*width: 45px;*/
    /*height: 41px;*/
  /*}*/

  div#logo {
    display: flex;
    padding-left: 1vw;
    padding-top: .2vw;
  }

  div#data-warehouse-logo-text {
    padding-left: .3vw;
  }

  div#data-warehouse-logo-text p {
    margin-bottom: 0px;
    margin-top: 0px;
    /*font-size: 2.5vw;*/
    font-weight: 600;
    color: #FFF;
    font-family: cursive;
  }

  div#root {
    /*padding-top: 20px;*/
    /*padding-left: 50px;*/
    /*padding-right: 50px;*/
    height: 100%;
    background-color: #f8f8f8;
    /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, .15)), to(rgba(0, 0, 0, .25)));*/
    /*background-image: url("../images/sky-535026_1920.jpg");*/
    /*background-color: #fffff8;*/
  }

  nav#router-nav {
    /*background-image: url("../images/sky-535026_1920.jpg");*/
    /*background-color: #1b44b7;*/
    background: -webkit-gradient(linear, 0% 0%, 100% 100%, from(#002b58), to(#125399));
    height: 68px;
    /*background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, .15)), to(rgba(0, 0, 0, .25))), -webkit-gradient(linear, left top, right bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(0.5, rgba(255, 255, 255, .1)), color-stop(0.501, rgba(255, 255, 255, 0)), color-stop(1, rgba(255, 255, 255, 0)));*/
    /*background: -moz-linear-gradient(top, rgba(255, 255, 255, .15), rgba(0, 0, 0, .25)), -moz-linear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));*/
    /*background: linear-gradient(top, rgba(255, 255, 255, .15), rgba(0, 0, 0, .25)), linear-gradient(left top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0));*/

  }
  div#base{
      height: -webkit-fill-available;
      margin: 8px;
  }
  .flex {
    display: flex;
  }

  .App-header {
    /*background-color: #282c34;*/
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  td.changed, tr.changed {
    border-color: #FFFF00;
    box-shadow: 0 0 .6vw #FFFF00;
  }

  a.underline-link {
    text-decoration: underline;
  }

  @media print {
    div.content {
      height: 100%;
      overflow: visible;
    }
  }
}

/* Logo Styling */
#logo {
  text-align: center;
}

#logo h1 {
  margin: 0;
  font-size: 1.2rem;
}
#logo h2 {
  margin: 0;
  font-size: 1.2rem;
}

#header {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  html { font-size: 90%; }
  .page-container {
    padding-top: 90px;
  }
  nav#router-nav {
    height: 52px;
    min-height: fit-content;
    max-height: 52px;
  }
}

@media (max-width: 480px) {
  html { font-size: 80%; }
  .page-container {
    padding-top: 80px;
  }
  nav#router-nav {
    height: 45px;
    min-height: fit-content;
    max-height: 45px;
  }
}

