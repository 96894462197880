.user-menu {
    display: flex;
    justify-content: right;
    padding: 0px 3rem;
}
/* User Menu */
.user-menu button {
    background-color: #12539900; /* Vibrant button color for light mode */
    color: #000000;
    border: none;
    padding: .3rem .3rem;
    font-size: 1.7rem;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    height: 3rem;
    min-height: 30px;
    min-width: 20px;
    white-space: nowrap;
}

.user-menu button:hover {
    background-color: #dae6ef; /* Darker shade for hover effect */
}